



































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { MeetingRequestsInboxView } from '@/utils/enums/MeetingRequestsInboxView';
import { getUnixTime } from 'date-fns';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import GqlPayloadParams from '@/utils/types/gql/GqlPayloadParams';
import * as StringHelper from '@/utils/helpers/StringHelper';
import MeetingRequest from '@/models/graphql/MeetingRequest';
import CompanyCalendarRepository from '@/repositories/CompanyCalendarRepository';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import EpMeetingRequestDashboardItemComponent
  from '@/components/exhibitor-portal/dashboard/meeting-request/EpMeetingRequestDashboardItemComponent.vue';
import SubscriptionEvent from '@/utils/types/SubscriptionEvent';
import Notification from '@/models/graphql/Notification';
import EntityType from '@/utils/enums/EntityType';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import NotificationEventType from '@/utils/enums/notification/NotificationEventType';

type FilterMenuOptionType = Record<string, {
  filter: object;
  list: Array<MeetingRequest>;
  emptyMessage: string;
}>;
/* eslint-disable @typescript-eslint/camelcase,no-underscore-dangle */
@Component({
  components: {
    EpMeetingRequestDashboardItemComponent,
    ButtonComponent,
    LoadingSpinnerComponent,
  },
})
export default class EpMeetingRequestDashboardWidget extends mixins(
  VueRegisterStoreWidget,
  VueBaseNotify,
  VueBaseWidget,
) {
  private filtersMenuOptions: FilterMenuOptionType = {
    [MeetingRequestsInboxView.INCOMING]: {
      filter: {
        ...this.baseGqlFilter,
        trash_not: true,
        meeting: null,
      },
      list: [],
      emptyMessage: `${this.$t('ep-dashboard.meeting-request.incoming-empty-message')}`,
    },
    [MeetingRequestsInboxView.PENDING]: {
      filter: {
        ...this.baseGqlFilter,
        meeting: {
          participants_some: {
            state: 'INVITED',
          },
          startTimestamp_gt: getUnixTime(new Date()),
        },
      },
      list: [],
      emptyMessage: `${this.$t('ep-dashboard.meeting-request.pending-empty-message')}`,
    },
    [MeetingRequestsInboxView.SCHEDULED]: {
      filter: {
        ...this.baseGqlFilter,
        _isFullyAcceptedFilter: '',
        meeting: {
          startTimestamp_gt: getUnixTime(new Date()),
        },
      },
      list: [],
      emptyMessage: `${this.$t('ep-dashboard.meeting-request.scheduled-empty-message')}`,
    },
  };

  private selectedFilter = MeetingRequestsInboxView.INCOMING;

  private repository = new CompanyCalendarRepository();

  private isLoading = true;

  private get emptyMessage(): string {
    return this.filtersMenuOptions[this.selectedFilter].emptyMessage;
  }

  private get list(): MeetingRequest[] {
    return this.filtersMenuOptions[this.selectedFilter].list;
  }

  private get baseGqlFilter(): object {
    const { companyId } = this.$route.params;
    return {
      exhibitor: {
        uid: companyId,
      },
    };
  }

  private get constructGqlDefinition(): GqlPayloadParams {
    return {
      operationName: `Load${StringHelper.capitalize(this.selectedFilter)}MeetingRequests`,
      definition: buildQueryDefinition({
        filter: {
          type: GqlEntityFilterType.MEETING_REQUEST_FILTER,
          value: this.filtersMenuOptions[this.selectedFilter].filter,
        },
        orderBy: {
          type: GqlEntityOrderingType.MEETING_REQUEST_ORDERING,
          value: ['readState_asc', 'createdTimestamp_desc'],
        },
      }),
      fragmentName: 'meetingRequestDashboardFragment',
      authUser: this.authUser?.uid || '',
    };
  }

  created(): void {
    this.notifyEvents = [NotificationEventType.MEETING_REQUEST, NotificationEventType.MEETING_PARTICIPANT];
    this.setDataConfig();
    this.onFilter(this.selectedFilter);
  }

  notificationCallback(event: SubscriptionEvent): void {
    const notification = Notification.hydrate(event.data);
    if (notification
      && notification.triggered
      && ((notification.action === 'MEETING_REQUEST_RECEIVED'
          && this.list.filter((m) => notification.triggered?.uid === m.uid).length === 0
          && this.selectedFilter === MeetingRequestsInboxView.INCOMING)
        || (notification.action === 'MEETING_REQUEST_ASSIGNED_MEETING'
          && this.selectedFilter === MeetingRequestsInboxView.SCHEDULED)
        || (['MEETING_ACCEPTED', 'MEETING_DECLINED'].includes(notification.action as string)
          && notification.triggered.__typename === EntityType.MEETING_PARTICIPANT
          && [MeetingRequestsInboxView.PENDING, MeetingRequestsInboxView.SCHEDULED].includes(this.selectedFilter)))) {
      this.onFilter(this.selectedFilter);
    }
  }

  private onFilter(filter: MeetingRequestsInboxView): void {
    this.isLoading = true;
    this.selectedFilter = filter;
    this.repository.loadMeetingRequest(this.constructGqlDefinition)
      .then((response) => {
        this.filtersMenuOptions[this.selectedFilter].list = response;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private goToMeetingRequests(): void {
    this.$router.push({
      name: 'exhibitor-portal-meeting-requests',
      query: {
        tabView: this.selectedFilter,
      },
    });
  }
}
